<template>
  <div class="flex-col flex">
    <div class="mb-3" v-if="success" data-cy="success-message">
      Registreerimine õnnestus!
    </div>
    <div
      class="error-box mb-3 flex flex-col"
      v-if="$v.$error || error"
      data-cy="error-message"
    >
      <span v-if="!$v.user.email.required" class="flex"
        >Palun sisestage email</span
      >
      <span v-if="!$v.user.email.email">Meiliaadress ei vasta nõuetele</span>
      <span v-if="!$v.user.first_name.required">Palun sisestage eesnimi</span>
      <span v-if="!$v.user.last_name.required"
        >Palun sisestage perekonnanimi</span
      >
      <span v-if="!$v.user.password.required">Palun sisestage salasõna</span>
      <span v-if="!$v.user.password.minLength"
        >Salasõna ei ole piisavalt pikk</span
      >
      <span>{{ errorText }}</span>
    </div>
    <div class="form-group w-full mb-5">
      <label class="flex">Meiliaadress</label>
      <input
        type="email"
        class="w-full shadow-none focus:shadow-outline"
        v-model="user.email"
        data-cy="email"
      />
    </div>
    <div class="form-group w-full mb-5">
      <div class="flex flex-row w-full mobile:flex-col">
        <div
          class="flex flex-col items-start w-6/12 mr-1 mobile:w-full mobile:mr-0 mobile:mb-5"
        >
          <label>Eesnimi</label>
          <input
            type="text"
            class="w-full shadow-none focus:shadow-outline"
            v-model="user.first_name"
            data-cy="first-name"
          />
        </div>
        <div
          class="flex flex-col items-start w-6/12 ml-1 mobile:w-full mobile:ml-0"
        >
          <label>Perekonnanimi</label>
          <input
            type="text"
            class="w-full shadow-none focus:shadow-outline"
            v-model="user.last_name"
            data-cy="last-name"
          />
        </div>
      </div>
    </div>
    <div class="form-group w-full mb-5">
      <label class="flex">Salasõna</label>
      <input
        type="password"
        class="w-full shadow-none focus:shadow-outline"
        v-model="user.password"
        data-cy="password"
      />
    </div>
    <div class="flex justify-start items-center mb-4">
      <div class="flex w-auto justify-center mr-4">
        <base-checkbox
          :base-val="acceptTerms"
          @valueChanged="
            val => {
              acceptTerms = val;
            }
          "
        ></base-checkbox>
      </div>
      <div class="w-10/12 flex">
        <span
          >Olen lugenud ning nõustun‎
          <a
            href="/terms"
            target="_blank"
            class="hover:text-green font-semibold"
            >kasutajatingimuste</a
          >
          ja
          <a
            href="/privacy-policy"
            target="_blank"
            class="hover:text-green font-semibold"
            >privaatsussätetega</a
          >.</span
        >
      </div>
    </div>
    <div class="flex justify-start items-center mb-8">
      <div class="w-auto flex justify-center mr-4">
        <base-checkbox
          :base-val="acceptNews"
          @valueChanged="
            val => {
              acceptNews = val;
            }
          "
        >
        </base-checkbox>
      </div>
      <div class="w-auto flex">
        <span>
          Soovin soodustusi, pakkumisi ja infot uuenduste kohta (maksimaalselt 1
          kord nädalas)
        </span>
      </div>
    </div>
    <button
      v-if="success === false"
      @click="createUser(user)"
      data-cy="submit"
      class="new-button-green py-2"
    >
      Registreeri
    </button>
    <div class="flex justify-center mt-3">
      <span class="font-semiibold"
        >Oled juba kasutaja?
        <router-link to="/login" class="hover:text-green"
          >Vajuta siia, et sisse logida!</router-link
        >
      </span>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import jwtdecode from "jwt-decode";
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  name: "RegistrationFormJoin",
  mixins: [RequestHandler],
  data() {
    return {
      user: {
        email: "",
        first_name: "",
        last_name: "",
        password: ""
      },
      acceptTerms: false,
      acceptNews: false,
      success: false,
      error: false,
      errorText: ""
    };
  },
  methods: {
    createUser(user) {
      user.is_posting_job = this.to_job;
      user.email = user.email.toLowerCase();
      this.$v.$touch();
      if (this.$v.$error || this.acceptTerms === false) {
        this.error = true;
        if (this.acceptTerms === false) {
          this.errorText =
            "Kasutajatingimuste ja privaatsussätetega nõustumine on registreerimiseks kohustuslik";
        }
      } else {
        this.$store.state.loading = true;
        this.$store.dispatch("auth/createUser", { user }).then(
          res => {
            if (res.status === 201) {
              this.$store.state.loading = false;
              if (this.acceptNews) {
                const userToken = jwtdecode(this.$store.state.auth.user);
                this.apiRequest(
                  "profiles/" + userToken.user_id + "/settings/",
                  "patch",
                  true,
                  {
                    notification_choices: {
                      promotional_emails: true
                    }
                  }
                );
              }
              this.apiRequest(
                "/company/merge/" +
                  jwtdecode(this.$store.state.auth.user).user_id +
                  "/",
                "post",
                true,
                { token: this.$route.query.token }
              ).then(res => {
                if (res.status === 200) {
                  this.$router.push("/calendar");
                }
              });
            }
          },
          err => {
            if (err.response.status === 400) {
              this.errorText = "E-posti aadress on juba kasutusel";
              this.error = true;
            }
            if (err.response.status === 406) {
              this.errorText = "Parool on nimega liiga sarnane";
              this.error = true;
            }
            if (err.response.status === 409) {
              this.errorText = "E-posti aadress on juba kasutusel";
              this.error = true;
            }
            if (err.response.status === 500) {
              this.errorText = "Serverierror, sa ei tohiks siin olla";
              this.error = true;
            }
            this.$store.state.loading = false;
          }
        );
      }
    },
    back() {
      this.$emit("navigateBack");
    }
  },
  validations: {
    user: {
      email: { required, email },
      first_name: { required },
      last_name: { required },
      password: { required, minLength: minLength(8) }
    }
  }
};
</script>
